<template>
  <div class="terms">
    <TermsAndConditionTitle />
    <TermsAndConditionContent />
  </div>
</template>

<script>
import TermsAndConditionTitle from "../components/TermsAndConditionTitle.vue";
import TermsAndConditionContent from "../components/TermsAndConditionContentv2.vue";

export default {
  name: "Terms",
  components: {
    TermsAndConditionTitle,
    TermsAndConditionContent,
  },
  data() {
    return {
      title: "Powering digital payments and identity verification",
      description:
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.";
    },
  },
};
</script>
